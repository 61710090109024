import type { drive_v3 } from '@googleapis/drive';
import { defineStore } from 'pinia';
import type { CloudFile, FileProperties } from '~/types/CloudFile';

export const useFileStore = defineStore('files', () => {
    const { t } = useI18n();
    const filelist = ref<CloudFile[]>([]);

    const defaultProperties: FileProperties = {
        title: t('file.default-title'),
    };

    const findFile = (id: string) => filelist.value.find((entry) => entry.name === id);

    const addFile = (file: drive_v3.Schema$File) => {
        // First check if the file is already in the store
        const foundFile = findFile(file.id!);

        if (foundFile) {
            return foundFile;
        }

        const cloudFile: CloudFile = {
            ...file,
            type: 'googledrive',
            properties: {
                ...defaultProperties,
                ...file.properties,
            },
        };

        filelist.value.push(cloudFile);

        return cloudFile;
    };

    const addFiles = (files: CloudFile[]) => {
        files.forEach((file) => addFile(file));
    };

    const removeFile = (id: string) => {
        const index = filelist.value.findIndex((entry) => entry.id === id);
        if (index !== -1) {
            filelist.value.splice(index, 1);
        }
    };

    const fetchFile = async (id: string) =>
        useFetch<CloudFile>(`/api/files/${id}`, {
            transform: (file) => {
                return addFile(file);
            },
        });

    const valueOr = (value: string | undefined, defaultValue = '-') =>
        value?.trim().length ? value.trim() : defaultValue;

    return {
        filelist,
        findFile,
        fetchFile,
        addFile,
        addFiles,
        removeFile,
        valueOr,
    };
});
